@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500&display=swap");
*,
body,
html {
  margin: 0;
  padding: 0;
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: orange; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid orange; /* creates padding around scroll thumb */
}
.main-page-wrapper {
  height: 100vh;
  width: 100%;
  /* background: linear-gradient(rgba(38, 38, 38, 0.8), rgba(38, 38, 38, 0.8)),
    url("/src/assets/background.svg"); */
  background: #000;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.headline-row {
  text-align: center;
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: normal;
  padding-top: 3%;
}
.headline-row p {
  margin-top: 33px;
  font-weight: 200;
}
.form-row {
  margin-top: 91px !important;
  text-align: center;
}
.form-control {
  width: 30% !important;
  margin-bottom: 39px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 1%;
  margin: auto;
}
.form-control::placeholder {
  color: #000;
  font-size: 13px;
}
.search-button {
  height: 50px;
  width: 13%;
  border-radius: 10px;
  background-color: #363636;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.09em;
}
.disabled-button {
  background-color: #363636;
  opacity: 0.5;
  height: 50px;
  width: 13%;
  border-radius: 10px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.09em;
}
.search-button:hover {
  background-color: #242323;
}
.mms-image {
  width: 35%;
}
.footer-row {
  margin-top: 5% !important;
  display: flex;
  align-items: center;
}
.ajet {
  color: #fff;
  font-weight: 200;
  margin-top: 25px !important;
}
.el-bekare {
  color: #fff;
  font-weight: 200;
  text-align: right;
  padding-right: 15%;
}
.result-mms {
  width: 35%;
  cursor: pointer;
}
.results-page-header {
  padding-top: 1%;
}
.back-to-search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100%;
}
.back-to-search img {
  width: 1%;
  margin-right: 3%;
  cursor: pointer;
}
.back-to-search span {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  cursor: pointer;
}
.search-result-div {
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-size: 17px;
}
.search-name {
  font-size: 30px;
  font-weight: 400;
  text-transform: capitalize;
}
.results-row {
  width: 90%;
  margin: auto !important;
  margin-top: 50px !important;
}
.result-card-wrapper {
  background-color: #e5e5e5;
  margin-bottom: 5%;
  padding: 10px;
  border-radius: 10px;
}
.name-surname-card {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 1.1em;
}
.dob-card {
  margin: 15px 0 !important;
}
.open-card-button {
  background-color: #231f20;
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  border: none;
  outline: none;
  width: 40%;
  border-radius: 20px;
  padding: 5px;
}
.container-map {
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}
.map-container {
  height: 100%;
}
.map-header-wrapper {
  background-color: #231f20;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffff;
  font-family: "Barlow", sans-serif;
  margin: 0 !important;
}

.info-img-wrapper {
  font-weight: 200;
  display: flex;
  justify-content: center;
}
.info-img-wrapper img {
  margin-right: 10px;
  cursor: pointer;
}
.info-img-wrapper p {
  cursor: pointer;
}
.small-button {
  width: 90%;
  margin: auto;
}
.loading-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info-container {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 98%;
  margin-left: 16px !important;
  top: 90%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex !important;
  align-items: center;
  font-family: "Barlow", sans-serif;
}
.info-container h4,
p {
  margin: 5px 0 !important;
  padding: 0;
}
.info-container h4 {
  margin-bottom: 10px !important;
  font-weight: bold;
}
.info-container-heads {
  color: rgba(0, 20, 12, 0.75);
}
.info-container-date {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
}
.info-container-info {
  font-weight: 500;
}
.info-container.close {
  animation-name: closeContainer;
  animation-duration: 0.5s;
  top: 150%;
}
.info-container.open {
  animation-name: openContainer;
  animation-duration: 0.5s;
  top: 80%;
}
.map-mms {
  width: 25%;
}
.logo-container {
  display: flex;
  justify-content: center;
}
.visiot-logo-black {
  height: 60px;
  width: 60px;
}
.visiot-main {
  color: #fff;
}
.dev-by {
  color: rgba(255, 255, 255, 0.5);
  font-size: 400;
  margin-left: 10px !important;
}
.map-logo {
  width: 30% !important;
}
.visiot-map-logo {
  text-decoration: none !important;
  position: absolute !important;
  left: 10px !important;
  top: 120px !important;
}
@keyframes closeContainer {
  0% {
    top: 90%;
  }
  100% {
    top: 150%;
  }
}

@keyframes openContainer {
  0% {
    top: 150%;
  }
  100% {
    top: 90%;
  }
}
.hehe {
  position: absolute;
  top: 50%;
}
@media (max-width: 916px) {
  .result-back {
    height: 20px !important;
    width: 20px !important;
  }
}
@media (max-width: 666px) {
  .back-text {
    display: none;
  }
  .back-arrow {
    height: 30px !important;
    width: 30px !important;
  }
}
@media (max-width: 580px) {
  .main-mms {
    width: 30% !important;
    margin-bottom: 10%;
  }
  .map-logo {
    width: 100% !important;
  }
  .info-container {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 98%;
    margin-left: 2px !important;
    top: 70% !important;
    padding-top: 20px !important;
    display: flex;
    flex-direction: column;
  }
  .info-container-content {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 426px) {
  .visiot-logo-black {
    height: 40px;
    width: 40px;
  }
  .logo-container {
    display: flex;
    align-items: center;
  }

  .main-page {
    padding-top: 10% !important;
  }
  .footer-row {
    margin-top: 12% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-row {
    margin-top: 100px !important;
    display: flex;
    align-items: center;
  }
  .mms-image {
    width: 45% !important;
    margin-bottom: 30px;
  }
  .back-text {
    display: none;
  }
  .back-arrow {
    height: 30px !important;
    width: 30px !important;
  }
  .map-mms {
    height: 35px !important;
    width: 130px !important;
    margin-top: 20px !important;
  }
  .info-text {
    display: none;
  }
  .mms-result {
    width: 80% !important;
    margin-top: 20px;
  }
  .visiot-map-logo {
    top: 90 !important;
  }
}
@media (max-width: 575px) {
  .info-container.open {
    top: 88%;
  }
}
@media (max-width: 768px) {
  .main-page {
    padding-top: 10%;
  }
  .form-row {
    margin-top: 20% !important;
  }
  .form-control {
    width: 50% !important;
  }
  .footer-row {
    margin-top: 10% !important;
    display: flex;
    align-items: center;
  }
  .mms-image {
    width: 120% !important;
    padding-right: 25px !important;
  }
  .search-button {
    width: 40%;
  }
  .disabled-button {
    width: 40%;
  }
  .info-container {
    margin-left: 2px !important;
  }
  .main-mms-image {
    width: 60% !important;
  }
  .visiot-map-logo {
    top: 90px !important;
  }
}
.oNama {
  font-weight: normal;
  color: #fff;
}
.text-row {
  width: 60% !important;
  margin: auto !important;
  color: #fff;
  font-weight: 200;
  text-align: center;
}
.paragraph {
  margin-top: 5% !important;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}
.link:hover {
  color: #fff;
  text-decoration: underline;
}

.open {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-container-ime {
  flex: 1;
}
.info-container-content {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-container-infos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 4;
}
.info-container-button {
  flex: 1;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  overflow: hidden;
}
